(function () {
  const throttle = function (fn, delay) {
    delay || (delay = 100);
    var throttle = false;
    return function () {
      if (throttle) {
        return;
      } // you no enter
      throttle = setTimeout(function () {
        // tail it - and do one last ajax request
        fn.apply(this, arguments);
        throttle = false;
      }, delay);
      fn.apply(this, arguments);
    };
  };

  const sliders = document.querySelectorAll("[data-lalilala-slider]");
  if (sliders && sliders.length) {
    sliders.forEach((sliderWrapper) => {
      let flkty = null;
      let flktyNext = () => {};
      let flktyPrevious = () => {};
      let pageDotTemplate = null;
      const pageDot = sliderWrapper.querySelector(
        '[data-lalilala-slider-el="dot"]'
      );
      if (pageDot) {
        pageDotTemplate = pageDot.cloneNode();
      }

      const initiateFlickity = () => {
        const slider = sliderWrapper.querySelector(
          '[data-lalilala-slider-el="slider"]'
        );
        const groupCells = !!slider.getAttribute(
          "data-lalilala-slider-group-cells"
        );
        const wrapAround = !!slider.getAttribute(
          "data-lalilala-slider-wrap-around"
        );
        const sliderArrowLeft = sliderWrapper.querySelector(
          '[data-lalilala-slider-el="arrow-left"]'
        );
        const sliderArrowRight = sliderWrapper.querySelector(
          '[data-lalilala-slider-el="arrow-right"]'
        );
        const pageDots = sliderWrapper.querySelector(
          '[data-lalilala-slider-el="dots"]'
        );

        if (flkty) {
          flkty.destroy();
          if (sliderArrowRight) {
            sliderArrowRight.removeEventListener("click", flktyNext);
          }
          if (sliderArrowLeft) {
            sliderArrowLeft.removeEventListener("click", flktyPrevious);
          }
        }

        flkty = new Flickity(slider, {
          contain: true,
          imagesLoaded: true,
          pageDots: false,
          groupCells,
          wrapAround,
          prevNextButtons: false,
        });
        flktyPrevious = () => flkty.previous();
        flktyNext = () => flkty.next();

        if (pageDots && pageDotTemplate) {
          pageDots.innerHTML = "";
          if (flkty.slides.length > 1) {
            for (let i = 0; i < flkty.slides.length; i++) {
              const newPageDot = pageDotTemplate.cloneNode();
              pageDots.appendChild(newPageDot);
              if (i === flkty.selectedIndex) {
                newPageDot.classList.add("is-selected");
              }
              newPageDot.addEventListener("click", () => {
                console.log("click", i);
                flkty.select(i);
              });
            }

            flkty.on("select", (index) => {
              const selected = pageDots.querySelector(".is-selected");
              if (selected) {
                selected.classList.remove("is-selected");
              }
              pageDots.children.item(index).classList.add("is-selected");
            });
          }
        }

        if (sliderArrowLeft) {
          const toggleLeftArrow = () => {
            sliderArrowLeft.classList.toggle(
              "is-disabled",
              flkty.selectedIndex === 0 && !wrapAround
            );
          };
          sliderArrowLeft.addEventListener("click", flktyPrevious);
          toggleLeftArrow();
          flkty.on("change", toggleLeftArrow);
        }

        if (sliderArrowRight) {
          const toggleRightArrow = () => {
            sliderArrowRight.classList.toggle(
              "is-disabled",
              flkty.selectedIndex === flkty.slides.length - 1 && !wrapAround
            );
          };
          sliderArrowRight.addEventListener("click", flktyNext);

          toggleRightArrow();
          flkty.on("change", toggleRightArrow);
        }
      };

      initiateFlickity();
      window.addEventListener("resize", throttle(initiateFlickity, 200));
    });
  }
})();
