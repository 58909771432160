(function () {
  const simulateClick = function (elem) {
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    elem.dispatchEvent(evt);
  };

  const throttle = function (fn, delay) {
    delay || (delay = 100);
    var throttle = false;
    return function () {
      if (throttle) {
        return;
      } // you no enter
      throttle = setTimeout(function () {
        // tail it - and do one last ajax request
        fn.apply(this, arguments);
        throttle = false;
      }, delay);
      fn.apply(this, arguments);
    };
  };

  const observedElements = document.querySelectorAll(
    "[data-lalilala-stagger-animation]"
  );
  if (observedElements && observedElements.length) {
    const options = {
      threshold: 0.3,
    };
    const inViewCallback = (entries) => {
      let index = 0;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const timingAttribute = entry.target.getAttribute(
            "data-lalilala-stagger-animation"
          );
          const timing = !isNaN(timingAttribute)
            ? parseInt(timingAttribute)
            : 500;
          setTimeout(function () {
            simulateClick(entry.target);
          }, index * timing + Math.floor((Math.random() * timing) / 5));
          index++;
        }
      });
    };

    let observer = null;

    const createObserver = () => {
      if (observer) {
        observer.disconnect();
      }
      observer = new IntersectionObserver(inViewCallback, options);
      observedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    createObserver();

    window.addEventListener("resize", throttle(createObserver, 300));
  }
})();
